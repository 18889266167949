<template>
	<section class="s-national-championship" v-if="event">
		<h2 class="accessible-hide">{{ event.title }}</h2>
		<div class="component c-national-championship position-relative">
			<div
				class="c-national-championship__media aspect-ratio-1-1 overflow-hidden position-relative"
			>
				<picture
					class="c-national-championship__image utility-position-cover object-fit-cover"
					v-if="event_image && event_image[0].url !== ''"
				>
					<source
						:srcset="
							$root.get_image_resizer_url(
								`${$root.proxy}${image.url}`,
							)
						"
						:alt="image.alt"
						:media="`(min-width: ${image.breakpoint}px)`"
						v-for="(image, index) in event_image"
						:key="index"
					/>
					<img
						class="object-fit-cover height-100 width-100"
						v-lazy="
							$root.get_image_resizer_url(
								`${$root.proxy}${event_image[0].url}`,
							)
						"
						:alt="event_image[0].alt"
					/>
				</picture>
				<img
					class="utility-position-cover object-fit-cover height-100 width-100"
					src="@/assets/placeholder.jpg"
					alt=""
					v-else
				/>
			</div>
			<div
				class="c-national-championship__details display-flex flex-direction-column justify-content-flex-end font-size-14 height-100 position-absolute bottom-0 left-0 padding-24 text-white width-100 bp-768:flex-direction-row bp-768:align-items-flex-end bp-768:justify-content-space-between bp-768:font-size-18 bp-1024:padding-64 z-index-2"
			>
				<div
					class="c-national-championship__info bp-768:display-flex bp-768:align-items-center"
				>
					<img
						:src="
							$root.get_image_resizer_url(
								`${$root.proxy}${event.logo.url}`,
							)
						"
						:alt="event.logo.alt"
						class="c-national-championship__logo display-none margin-b-16 bp-540:display-block bp-768:margin-b-0 bp-768:margin-r-32"
						v-if="event.logo"
					/>
					<div>
						<div
							class="c-national-championship__date font-weight-700 text-transform-uppercase"
						>
							<span class="c-national-championship__date-start">{{
								event.start_date | luxon
							}}</span>
							<span
								v-if="
									event.end_date &&
										event.start_date !== event.end_date
								"
								class="margin-x-4"
								>-</span
							>
							<span
								v-if="
									event.end_date &&
										event.start_date !== event.end_date
								"
								class="c-national-championship__date-end"
								>{{ event.end_date | luxon }}</span
							>
						</div>
						<div
							class="c-national__championship__title header-font font-size-30 line-height-150 bp-768:font-size-48"
						>
							{{ event.title }}
						</div>
						<div class="c-national-championship__locale">
							<span
								class="c-national-championship__locale-location font-weight-700"
								>{{ event.city }}, {{ event.state }}</span
							>
							<span class="margin-x-4 text-muted-on-dark">|</span>
							<span
								class="c-national-championship__locale-stadium text-muted-on-dark"
								>{{ event.facility }}</span
							>
						</div>
					</div>
				</div>
				<ul
					class="c-national-championship__options margin-t-24 reset-list display-flex align-items-center flex-wrap-wrap"
				>
					<li
						class="c-national-championship__option margin-4"
						v-if="event.tickets_link && event.tickets_link !== ''"
					>
						<a
							class="c-national-championship__option-btn button --on-dark"
							:href="event.tickets_link"
							target="_blank"
							>Buy Tickets</a
						>
					</li>
					<li
						class="c-national-championship__option margin-4"
						v-if="
							event.ticket_options && event.ticket_options.length
						"
					>
						<button
							class="c-national-championship__option-btn reset button --on-dark"
							@click.prevent="toggle_ticket_options"
						>
							<span>Ticket Options</span>
							<plus-icon class="margin-l-6" />
						</button>
					</li>
					<li
						class="c-national-championship__option margin-4"
						v-if="event.links && event.links.length"
					>
						<button
							class="c-national-championship__option-btn reset button --on-light"
							@click.prevent="toggle_other_links"
						>
							<span>More Info</span>
							<plus-icon class="margin-l-6" />
						</button>
					</li>
				</ul>
				<div
					class="c-national-championship__overlay bg-color-primary display-flex flex-direction-column justify-content-center align-items-flex-start padding-t-16 padding-b-32 padding-x-16 position-absolute top-0 right-0 height-100 width-100 transition z-index-2 bp-768:width-33"
					v-if="event.ticket_options && event.ticket_options.length"
					:class="[
						{
							'opacity-0 pointer-events-none': !ticket_options_active,
						},
						{
							'opacity-1 pointer-events-auto is-active': ticket_options_active,
						},
					]"
				>
					<button
						class="c-national-championship__overlay-close margin-l-auto reset padding-16 cursor-pointer"
						@click.prevent="toggle_ticket_options"
						:class="[
							{
								'opacity-0': !ticket_options_active,
							},
							{
								'opacity-1 transition': ticket_options_active,
							},
						]"
					>
						<x-icon size="1.5x" class="text-on-primary"></x-icon>
					</button>
					<ul
						class="c-national-championship__overlay-list flex-item-1 overflow-y-auto padding-x-16 reset-list width-100 text-align-left center_text"
					>
						<li
							class="c-national-championship__overlay-item line-height-150 margin-b-12"
							v-for="(ticket_option,
							index) in event.ticket_options"
							:key="index"
							:class="[
								{
									'transform-translateY-100 opacity-0': !ticket_options_active,
								},
								{
									'transform-translateY-0 opacity-1 transition': ticket_options_active,
								},
							]"
						>
							<!-- NCAATIX-118 -->
							<navigation-link
								:url="ticket_option.link"
								_external-icon
								_class="c-national-championship__overlay-link display-flex font-size-16 text-on-primary text-decoration-none hover:text-decoration-underline"
							>
								<chevron-right-icon
									class="text-on-primary transition"
									size=".9x"
								></chevron-right-icon>
								<span
									class="flex-item-1 margin-l-4"
									v-html="
										ticket_option.title.replaceAll(
											/(?:\r\n|\r|\n)/g,
											'<br />',
										)
									"
									>{{ ticket_option.title }}
								</span>
							</navigation-link>
						</li>
					</ul>
				</div>
				<div
					class="c-national-championship__overlay bg-color-primary display-flex flex-direction-column justify-content-center align-items-flex-start padding-t-16 padding-b-32 padding-x-16 position-absolute top-0 right-0 height-100 width-100 transition z-index-2 bp-768:width-33"
					v-if="event.links && event.links.length"
					:class="[
						{
							'opacity-0 pointer-events-none': !other_links_active,
						},
						{
							'opacity-1 pointer-events-auto is-active': other_links_active,
						},
					]"
				>
					<button
						class="c-national-championship__overlay-close margin-l-auto reset padding-16 cursor-pointer"
						@click.prevent="toggle_other_links"
						:class="[
							{
								'opacity-0': !other_links_active,
							},
							{
								'opacity-1 transition': other_links_active,
							},
						]"
					>
						<x-icon size="1.5x" class="text-on-primary"></x-icon>
					</button>
					<ul
						class="c-national-championship__overlay-list flex-item-1 overflow-y-auto padding-x-16 reset-list width-100 text-align-left center_text"
					>
						<li
							class="c-national-championship__overlay-item line-height-150 margin-b-12"
							v-for="(link, index) in event.links"
							:key="index"
							:class="[
								{
									'transform-translateY-100 opacity-0': !other_links_active,
								},
								{
									'transform-translateY-0 opacity-1 transition': other_links_active,
								},
							]"
						>
							<navigation-link
								:url="link.link"
								_external-icon
								_class="c-national-championship__overlay-link display-flex font-size-16 text-on-primary text-decoration-none hover:text-decoration-underline"
							>
								<chevron-right-icon
									class="text-on-primary transition"
									size=".9x"
								></chevron-right-icon>
								<span
									class="flex-item-1 margin-l-4"
									v-html="
										link.title.replaceAll(
											/(?:\r\n|\r|\n)/g,
											'<br />',
										)
									"
								>
									{{ link.title }}
								</span>
							</navigation-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { ChevronRightIcon, PlusIcon, XIcon } from 'vue-feather-icons';
import { sort_object_reversed } from '@/helpers';
import NavigationLink from '../common/NavigationLink';

export default {
	name: 'NationalChampionship',

	components: {
		NavigationLink,
		ChevronRightIcon,
		PlusIcon,
		XIcon,
	},

	props: {
		event: {
			required: true,
			type: Object,
			default: null,
		},
	},

	data: () => ({
		other_links_active: false,
		ticket_options_active: false,
	}),

	computed: {
		event_image() {
			return sort_object_reversed(this.event.image, true);
		},
	},

	methods: {
		toggle_other_links() {
			this.other_links_active = !this.other_links_active;
		},
		toggle_ticket_options() {
			this.ticket_options_active = !this.ticket_options_active;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-national-championship {
	&__media {
		&::before {
			content: '';
			background: linear-gradient(
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.8) 100%
			);
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}
	}
	&__overlay {
		&-close {
			.c-national-championship__overlay.is-active & {
				transition-delay: 0.15s;
			}
			flex: 0 0 auto;
			top: 16px;
			right: 16px;
		}
		&-item {
			@for $i from 1 through 25 {
				&:nth-of-type(25n + #{$i}) {
					transition-delay: $i * 0.1s;
				}
			}
		}
		&-link {
			&:hover,
			&:focus {
				> svg {
					transform: translateX(3px);
				}
			}
		}
	}
}
.center_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
@media screen and (min-width: 540px) {
	.c-national-championship__media {
		padding-bottom: 56.25% !important;
	}
}
@media screen and (min-width: 1280px) {
	.c-national-championship__media {
		height: 500px;
		padding-bottom: 0 !important;
	}
}
</style>
