var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.event)?_c('section',{staticClass:"s-national-championship"},[_c('h2',{staticClass:"accessible-hide"},[_vm._v(_vm._s(_vm.event.title))]),_c('div',{staticClass:"component c-national-championship position-relative"},[_c('div',{staticClass:"c-national-championship__media aspect-ratio-1-1 overflow-hidden position-relative"},[(_vm.event_image && _vm.event_image[0].url !== '')?_c('picture',{staticClass:"c-national-championship__image utility-position-cover object-fit-cover"},[_vm._l((_vm.event_image),function(image,index){return _c('source',{key:index,attrs:{"srcset":_vm.$root.get_image_resizer_url(
							("" + (_vm.$root.proxy) + (image.url))
						),"alt":image.alt,"media":("(min-width: " + (image.breakpoint) + "px)")}})}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
						_vm.$root.get_image_resizer_url(
							("" + (_vm.$root.proxy) + (_vm.event_image[0].url))
						)
					),expression:"\n\t\t\t\t\t\t$root.get_image_resizer_url(\n\t\t\t\t\t\t\t`${$root.proxy}${event_image[0].url}`,\n\t\t\t\t\t\t)\n\t\t\t\t\t"}],staticClass:"object-fit-cover height-100 width-100",attrs:{"alt":_vm.event_image[0].alt}})],2):_c('img',{staticClass:"utility-position-cover object-fit-cover height-100 width-100",attrs:{"src":require("@/assets/placeholder.jpg"),"alt":""}})]),_c('div',{staticClass:"c-national-championship__details display-flex flex-direction-column justify-content-flex-end font-size-14 height-100 position-absolute bottom-0 left-0 padding-24 text-white width-100 bp-768:flex-direction-row bp-768:align-items-flex-end bp-768:justify-content-space-between bp-768:font-size-18 bp-1024:padding-64 z-index-2"},[_c('div',{staticClass:"c-national-championship__info bp-768:display-flex bp-768:align-items-center"},[(_vm.event.logo)?_c('img',{staticClass:"c-national-championship__logo display-none margin-b-16 bp-540:display-block bp-768:margin-b-0 bp-768:margin-r-32",attrs:{"src":_vm.$root.get_image_resizer_url(
							("" + (_vm.$root.proxy) + (_vm.event.logo.url))
						),"alt":_vm.event.logo.alt}}):_vm._e(),_c('div',[_c('div',{staticClass:"c-national-championship__date font-weight-700 text-transform-uppercase"},[_c('span',{staticClass:"c-national-championship__date-start"},[_vm._v(_vm._s(_vm._f("luxon")(_vm.event.start_date)))]),(
								_vm.event.end_date &&
									_vm.event.start_date !== _vm.event.end_date
							)?_c('span',{staticClass:"margin-x-4"},[_vm._v("-")]):_vm._e(),(
								_vm.event.end_date &&
									_vm.event.start_date !== _vm.event.end_date
							)?_c('span',{staticClass:"c-national-championship__date-end"},[_vm._v(_vm._s(_vm._f("luxon")(_vm.event.end_date)))]):_vm._e()]),_c('div',{staticClass:"c-national__championship__title header-font font-size-30 line-height-150 bp-768:font-size-48"},[_vm._v(" "+_vm._s(_vm.event.title)+" ")]),_c('div',{staticClass:"c-national-championship__locale"},[_c('span',{staticClass:"c-national-championship__locale-location font-weight-700"},[_vm._v(_vm._s(_vm.event.city)+", "+_vm._s(_vm.event.state))]),_c('span',{staticClass:"margin-x-4 text-muted-on-dark"},[_vm._v("|")]),_c('span',{staticClass:"c-national-championship__locale-stadium text-muted-on-dark"},[_vm._v(_vm._s(_vm.event.facility))])])])]),_c('ul',{staticClass:"c-national-championship__options margin-t-24 reset-list display-flex align-items-center flex-wrap-wrap"},[(_vm.event.tickets_link && _vm.event.tickets_link !== '')?_c('li',{staticClass:"c-national-championship__option margin-4"},[_c('a',{staticClass:"c-national-championship__option-btn button --on-dark",attrs:{"href":_vm.event.tickets_link,"target":"_blank"}},[_vm._v("Buy Tickets")])]):_vm._e(),(
						_vm.event.ticket_options && _vm.event.ticket_options.length
					)?_c('li',{staticClass:"c-national-championship__option margin-4"},[_c('button',{staticClass:"c-national-championship__option-btn reset button --on-dark",on:{"click":function($event){$event.preventDefault();return _vm.toggle_ticket_options($event)}}},[_c('span',[_vm._v("Ticket Options")]),_c('plus-icon',{staticClass:"margin-l-6"})],1)]):_vm._e(),(_vm.event.links && _vm.event.links.length)?_c('li',{staticClass:"c-national-championship__option margin-4"},[_c('button',{staticClass:"c-national-championship__option-btn reset button --on-light",on:{"click":function($event){$event.preventDefault();return _vm.toggle_other_links($event)}}},[_c('span',[_vm._v("More Info")]),_c('plus-icon',{staticClass:"margin-l-6"})],1)]):_vm._e()]),(_vm.event.ticket_options && _vm.event.ticket_options.length)?_c('div',{staticClass:"c-national-championship__overlay bg-color-primary display-flex flex-direction-column justify-content-center align-items-flex-start padding-t-16 padding-b-32 padding-x-16 position-absolute top-0 right-0 height-100 width-100 transition z-index-2 bp-768:width-33",class:[
					{
						'opacity-0 pointer-events-none': !_vm.ticket_options_active,
					},
					{
						'opacity-1 pointer-events-auto is-active': _vm.ticket_options_active,
					} ]},[_c('button',{staticClass:"c-national-championship__overlay-close margin-l-auto reset padding-16 cursor-pointer",class:[
						{
							'opacity-0': !_vm.ticket_options_active,
						},
						{
							'opacity-1 transition': _vm.ticket_options_active,
						} ],on:{"click":function($event){$event.preventDefault();return _vm.toggle_ticket_options($event)}}},[_c('x-icon',{staticClass:"text-on-primary",attrs:{"size":"1.5x"}})],1),_c('ul',{staticClass:"c-national-championship__overlay-list flex-item-1 overflow-y-auto padding-x-16 reset-list width-100 text-align-left center_text"},_vm._l((_vm.event.ticket_options),function(ticket_option,index){return _c('li',{key:index,staticClass:"c-national-championship__overlay-item line-height-150 margin-b-12",class:[
							{
								'transform-translateY-100 opacity-0': !_vm.ticket_options_active,
							},
							{
								'transform-translateY-0 opacity-1 transition': _vm.ticket_options_active,
							} ]},[_c('navigation-link',{attrs:{"url":ticket_option.link,"_external-icon":"","_class":"c-national-championship__overlay-link display-flex font-size-16 text-on-primary text-decoration-none hover:text-decoration-underline"}},[_c('chevron-right-icon',{staticClass:"text-on-primary transition",attrs:{"size":".9x"}}),_c('span',{staticClass:"flex-item-1 margin-l-4",domProps:{"innerHTML":_vm._s(
									ticket_option.title.replaceAll(
										/(?:\r\n|\r|\n)/g,
										'<br />'
									)
								)}},[_vm._v(_vm._s(ticket_option.title)+" ")])],1)],1)}),0)]):_vm._e(),(_vm.event.links && _vm.event.links.length)?_c('div',{staticClass:"c-national-championship__overlay bg-color-primary display-flex flex-direction-column justify-content-center align-items-flex-start padding-t-16 padding-b-32 padding-x-16 position-absolute top-0 right-0 height-100 width-100 transition z-index-2 bp-768:width-33",class:[
					{
						'opacity-0 pointer-events-none': !_vm.other_links_active,
					},
					{
						'opacity-1 pointer-events-auto is-active': _vm.other_links_active,
					} ]},[_c('button',{staticClass:"c-national-championship__overlay-close margin-l-auto reset padding-16 cursor-pointer",class:[
						{
							'opacity-0': !_vm.other_links_active,
						},
						{
							'opacity-1 transition': _vm.other_links_active,
						} ],on:{"click":function($event){$event.preventDefault();return _vm.toggle_other_links($event)}}},[_c('x-icon',{staticClass:"text-on-primary",attrs:{"size":"1.5x"}})],1),_c('ul',{staticClass:"c-national-championship__overlay-list flex-item-1 overflow-y-auto padding-x-16 reset-list width-100 text-align-left center_text"},_vm._l((_vm.event.links),function(link,index){return _c('li',{key:index,staticClass:"c-national-championship__overlay-item line-height-150 margin-b-12",class:[
							{
								'transform-translateY-100 opacity-0': !_vm.other_links_active,
							},
							{
								'transform-translateY-0 opacity-1 transition': _vm.other_links_active,
							} ]},[_c('navigation-link',{attrs:{"url":link.link,"_external-icon":"","_class":"c-national-championship__overlay-link display-flex font-size-16 text-on-primary text-decoration-none hover:text-decoration-underline"}},[_c('chevron-right-icon',{staticClass:"text-on-primary transition",attrs:{"size":".9x"}}),_c('span',{staticClass:"flex-item-1 margin-l-4",domProps:{"innerHTML":_vm._s(
									link.title.replaceAll(
										/(?:\r\n|\r|\n)/g,
										'<br />'
									)
								)}},[_vm._v(" "+_vm._s(link.title)+" ")])],1)],1)}),0)]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }